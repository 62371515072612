<template>
  <div class="login">
    <Form ref="loginForm" :model="form" :rules="rules">
      <FormItem prop="UserName">
        <Input
          type="text"
          v-model="form.UserName"
          placeholder="请输入用户名"
          disabled
        >
          <template #prepend>
            <Icon type="ios-person-outline"></Icon>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="Psw">
        <Input type="password" v-model="form.Psw" placeholder="请输入密码">
          <template #prepend>
            <Icon type="ios-lock-outline"></Icon>
          </template>
        </Input>
      </FormItem>
      <FormItem>
        <Button
          type="primary"
          style="width: 100%; height: 40px"
          @click="handleSubmit('form')"
          >登录</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
import { AdminLogin } from "@/api/Login";
import { setToken } from "@/libs/util";

export default {
  components: {},
  data() {
    return {
      value: 0,
      loading: false,
      form: {
        UserName: sessionStorage.userInfo
          ? JSON.parse(sessionStorage.userInfo).merchantName
          : "",
        Psw: "",
      },
      rules: {
        UserName: [
          { required: true, message: "账号不能为空", trigger: "blur" },
        ],
        Psw: [{ required: true, message: "密码不能为空", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    handleSubmit() {
      let UserName = this.form.UserName;
      let Psw = this.getEncrypt(this.form.Psw);
      AdminLogin(UserName, Psw).then((res) => {
        this.$Message.success(res.Message);
        window.sessionStorage.setItem("loginStatus", JSON.stringify(false));
        //setToken(res.Data.Token);
        setToken(this.getEncrypt(JSON.stringify(res.Data.UserInfo)));
      });
    },
    //加密
    getEncrypt(data) {
      let encrypt = new JSEncrypt();
      // 设置公钥
      let publicKey =
        "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHYRWdQfb0CbsTX2MTHPgKTDcswTYPmBfb6y08xcBwTTF36h9M0R2hflqU77pKe7CXU7svMAjMSuonb2JAjSl65PXQJDTCUI+YGhf++0BaFX+x92c1GwfdkBInlFmpBf+c8SBXUcqm0N6bEtK22ASGmvUlXyADVieIxLTyy4cVmPAgMBAAE=";
      encrypt.setPublicKey(publicKey);

      return encrypt.encrypt(data);
    },
  },
};
</script>


<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  .left-logo {
    height: 100%;
    width: 50%;
    float: left;
    // background-image: url("../../assets/images/login-bg.jpg");
    // background-size: cover;
    // background-position: center;
    // position: relative;
  }
  .right-form {
    height: 100%;
    width: 50%;
    float: right;
    .logo {
      width: 100px;
      margin: 30px auto;
    }
    .title {
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      color: #333;
      margin-bottom: 25px;
    }
    .title1 {
      height: 32px;
      text-align: center;
      font-size: 16px;
      margin-bottom: 25px;
    }
    :deep(.ivu-input-group > .ivu-input:last-child, .ivu-input-group-append) {
      height: 40px;
    }
    :deep(.ivu-form-item) {
      height: 50px;
    }
    :deep(.ivu-select-single .ivu-select-selection) {
      height: 40px;
    }
    :deep(.ivu-select-single .ivu-select-selection .ivu-select-selected-value) {
      line-height: 38px;
      height: 38px;
      margin-left: 8px;
    }
    :deep(.ivu-select-single .ivu-select-selection .ivu-select-placeholder) {
      line-height: 38px;
      height: 38px;
      margin-left: 8px;
    }
    :deep(.ivu-select-prefix) {
      padding: 13px 7px;
      display: inline-block;
      height: 38px;
      margin-top: -1px;
      font-size: inherit;
      font-weight: normal;
      line-height: 1;
      color: #515a6e;
      text-align: center;
      background-color: #f8f8f9;
      border-right: 1px solid #dcdee2;
      border-radius: 4px 0 0 4px;
    }
  }
}
</style>
