

import { MyRequest } from '@/libs/api.request'

export const AdminLogin = (UserName, Psw) => {
    const data = {
        UserName: UserName,
        Psw: Psw,
    }
    return MyRequest({ url: `/api/Login/Login`, method: 'post', data: data })
}

export const ModifyPwd = (params) => {
    return MyRequest({ url: `/api/Login/ModifyPwd`, method: 'post', data: params })
}