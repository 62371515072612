import {
  getBreadCrumbList,
  setTagNavListInSessionStorage,
  getMenuByRouter,
  getTagNavListFromSessionStorage,
  getHomeRoute,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled,
  localSave,
  localRead,
  initRouterNode
} from '@/libs/util'

import router from '@/router'
import routers from '@/router/routers'
import config from '@/config'
import store from '@/store'
import dbRouters from '@/router/dbRouter'
const { homeName } = config

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}

export default {
  state: {
    breadCrumbList: [],
    tagNavList: [],
    homeRoute: {},
    local: localRead('local'),
    errorList: [],
    hasReadErrorPage: false,
    mergeRouter: []
  },
  getters: {
    menuList: (state, getters, rootState) => getMenuByRouter(state.mergeRouter, rootState.user.access),
    errorCount: state => state.errorList.length
  },
  mutations: {
    setBreadCrumb(state, route) {
      state.breadCrumbList = getBreadCrumbList(state.homeRoute, route)
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getHomeRoute(routes, homeName)
    },
    setTagNavList(state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromSessionStorage() || []
      // if (tagList[0] && tagList[0].name !== homeName) tagList.shift()
      let homeTagIndex = tagList.findIndex(item => item.name === homeName)
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
	  tagList = tagList.filter(item => item.name != "exam-edit" && item.name != "score-statistics" && item.name !="questions-add")
      state.tagNavList = tagList
      setTagNavListInSessionStorage([...tagList])
    },
    closeTag(state, route) {
      let tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    },
    addTag(state, { route, type = 'unshift' }) {
      let router = getRouteTitleHandled(route)
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === homeName) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInSessionStorage([...state.tagNavList])
      }
    },
    setLocal(state, lang) {
      localSave('local', lang)
      state.local = lang
    },
    addError(state, error) {
      state.errorList.push(error)
    },
    setHasReadErrorLoggerStatus(state, status = true) {
      state.hasReadErrorPage = status
    },
    setDymcRouter(state, dymcRouterData) {
      let mergeRouterList = Object.assign([], routers)
      // dymcRouterData = dbRouters;
      if (dymcRouterData) {
        mergeRouterList.push(...dymcRouterData)
      }
      state.mergeRouter = []//避免重复菜单
      initRouterNode(state.mergeRouter, mergeRouterList)
      state.mergeRouter.forEach((item) => {
        router.addRoute(item)
      })

      //路由本地持久化动态数组数据
      sessionStorage.setItem("mergeRouter", JSON.stringify(dymcRouterData))

      // sessionStorage.setItem("mergeRouter", JSON.stringify(dbRouters))
    }
  },
  actions: {
    addErrorLog({ commit, rootState }, info) {
      if (!window.location.href.includes('error_logger_page')) commit('setHasReadErrorLoggerStatus', false)
      const { user: { token, userId, userName } } = rootState
      let data = {
        ...info,
        time: Date.parse(new Date()),
        token,
        userId,
        userName
      }
    },
    setMenuData({ commit, rootState }, data) {
      commit('setDymcRouter', data)
    },
    handleTimeout({ commit, rootState }) {
      // alert('操作超时请重新登陆')
      store.dispatch('handleLogOut').then(res => {
        router.push({
          name: 'login'
        })
      })

    }
  }
}
