import HttpRequest from '@/libs/axios'
import config from '@/config'
import ViewUIPlus from 'view-ui-plus'
import { Message, Modal } from "view-ui-plus";
import store from '@/store'
import router from '@/router'

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? config.baseUrl.dev
    : config.baseUrl.pro

export const Base = {
  baseUrl,
}

export const MyRequest = (requestObj) => {
  return new Promise((resolve, reject) => {
    ViewUIPlus.LoadingBar.start()
    new HttpRequest(baseUrl)
      .request({
        url: requestObj.url,
        data: requestObj.data,
        method: requestObj.method,
        headers: requestObj.headers
      })
      .then((res) => {
        ViewUIPlus.LoadingBar.finish()
        if (res.status == '200') {
          if (res.data.Result) {
            resolve(res.data)
          } else {
            if (res.data.Message == '登录信息过期') {
              Modal.confirm({
                title: '提示',
                content: '<p>' + res.data.Message + '</p>',
                okText: '登录',
                cancelText: '退出',
                onOk: () => {
                  window.sessionStorage.setItem(
                    "loginStatus",
                    JSON.stringify(true)
                  );
                },
                onCancel: () => {
                  store.dispatch('handleLogOut').then(() => {
                    router.go({
                      name: 'login'
                    })
                  })
                },
              });
            } else {
              reject(res)
              Message.warning(res.data.Message);
            }
          }
        } else {
          alert('请求接口失败，请检查网络')
        }
        if (requestObj.callback) {
          requestObj.callback()
        }
      }).catch((err) => {
        reject(err)
      })
  })
}
