export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录',
      hideInMenu: true
    },
    component: () => import("@/views/login/login.vue")
  },
  {
    path: '/online-exam-login',
    name: 'online-exam-login',
    meta: {
      title: '在线考试 - 登录',
      hideInMenu: true
    },
    component: () => import("@/views/online-exam/online-exam-login.vue")
  },
  {
    meta: {
      title: "题库中心",
      hideInBread: false,
      hideInMenu: true,
      notCache: false,
      firstMenu: false,
      icon: "ios-home",
    },
    path: "/Topic",
    name: "_Topic",
    component: "Main",
    icon: "wendang",
    children: [
      {
        meta: {
          title: "添加试题",
          hideInBread: false,
          hideInMenu: true,
          notCache: false,
        },
        name: "questions-add",
        path: "questions-add",
        component: "/Topic/questions-add.vue",
      },
	  {
	    meta: {
	      title: "试题管理",
	      hideInBread: false,
	      hideInMenu: true,
	      notCache: false,
	    },
	    name: "questions-manage",
	    path: "questions-manage",
	    component: "/Topic/questions-manage.vue",
	  },
    ],
  },
  {
    meta: {
      title: "发起考试",
      hideInBread: false,
      hideInMenu: true,
      notCache: false,
      firstMenu: false,
      icon: "chengjishenhe-02",
    },
    path: "/Exam",
    name: "_Exam",
    component: "Main",
    icon: "chengjishenhe-02",
    children: [
      {
        meta: {
          title: "新增考试",
          hideInBread: false,
          hideInMenu: true,
          notCache: false,
        },
        name: "exam-add",
        path: "exam-add",
        component: "/Exam/exam-add.vue",
      },
	  {
	    meta: {
	      title: "编辑考试",
	      hideInBread: false,
	      hideInMenu: true,
	      notCache: false,
	    },
	    name: "exam-edit",
	    path: "exam-edit",
	    component: "/Exam/exam-edit.vue",
	  },
	  {
	    meta: {
	      title: "成绩统计",
	      hideInBread: false,
	      hideInMenu: true,
	      notCache: false,
	    },
	    name: "score-statistics",
	    path: "score-statistics",
	    component: "/Exam/score-statistics.vue",
	  },
    ],
  },
  {
    path: '/online-exam',
    name: 'online-exam',
    meta: {
      title: '考试信息',
      hideInMenu: true
    },
	component: "/online-exam/online-exam.vue",
  },
  {
    path: '/online-exam-topic',
    name: 'online-exam-topic',
    meta: {
      title: '考试试题',
      hideInMenu: true
    },
	component: "/online-exam/online-exam-topic.vue",
  },
  {
    path: '/online-exam-submit',
    name: 'online-exam-submit',
    meta: {
      title: '答题完成',
      hideInMenu: true
    },
	component: "/online-exam/online-exam-submit.vue",
  },
  {
    path: '/exam-correct',
    name: 'exam-correct',
    meta: {
      title: '查看试题',
      hideInMenu: true
    },
	component: "/Exam/exam-correct.vue",
  },
  {
    path: '/exam-view',
    name: 'exam-view',
    meta: {
      title: '我的试卷',
      hideInMenu: true
    },
	component: "/Exam/exam-view.vue",
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '查询',
      hideInMenu: true
    },
	component: "/online-exam/search.vue",
  },
  
]