<template>
  <div id="app">
    <Modal
      width="360"
      :mask-closable="false"
      v-model="modal"
      title="登录"
      :footer-hide="true"
    >
      <Login></Login>
    </Modal>
    <router-view />
  </div>
</template>

<script>
import Login from "./components/LoginModal/LoginModal.vue";
export default {
  name: "App",
  components: { Login },
  data() {
    return {
      modal: sessionStorage.loginStatus
        ? JSON.parse(sessionStorage.loginStatus)
        : false,
    };
  },
  mounted() {
    let that = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key == "loginStatus") {
        that.modal = JSON.parse(e.newValue);
      }
    });
  },
};
</script>
<style lang="less">
.size {
  width: 100%;
  height: 100%;
}
html,
body {
  .size;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#app {
  .size;
}
.ivu-table-small td {
  height: 30px;
}
.ivu-btn-small {
  font-size: 10px;
  height: 20px;
}
.ivu-table td {
  height: 30px;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.main .content-wrapper {
  overflow: auto;
}
</style>
