import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/config'
import './styles/index.less'
import dayjs from 'dayjs'
import tool from './utils/tool.js'
import '@/assets/iconfont/iconfont.css'

// import '../public/font/iconfont.css'
import * as echarts from 'echarts'
const app = createApp(App)
app.config.globalProperties.$config = config;
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$echarts = echarts

app.use(store)
  .use(router)
  .use(ViewUIPlus)
  .use(tool)
  .mount('#app')
