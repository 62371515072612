import { createRouter, createWebHashHistory } from "vue-router";
import routes from './routers'
import store from '@/store'
import ViewUIPlus from 'view-ui-plus'
import { setToken, getToken, canTurnTo, setTitle } from '@/libs/util'
import config from '@/config'
const { homeName } = config

const router = createRouter({
  routes,
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
})
const LOGIN_PAGE_NAME = 'login'

const turnTo = (to, access, next) => {
  next()
  // if (canTurnTo(to.name, access, routes)) next() // 有权限，可访问
  // else next({ replace: true, name: 'error_401' }) // 无权限，重定向到401页面
}

router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start();
  if (to.name === LOGIN_PAGE_NAME) {
    setToken('')
  }
  const token = getToken()
  // console.log("from",from);
  // console.log("to",to);
  // console.log("token",token);
  if(to.name == "online-exam-login"){
	  next()
  }else if (!token && to.name !== LOGIN_PAGE_NAME) {
    // 未登录且要跳转的页面不是登录页
	// if (to.path === "/online-exam") {
	//   // 未登录且要跳转的页面不是登录页
	//   next({
	//     name: LOGIN_PAGE_NAME, // 跳转到登录页
	// 	query : {
	// 		Id : to.query.Id
	// 	}
	//   })
	//   return
	// }
    next({
      name: LOGIN_PAGE_NAME, // 跳转到登录页
    })
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    next() // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    next({
      name: homeName, // 跳转到homeName页
    })
  } else {
    //从sessionStorage获取动态路由数据
    let mergeRouterData = JSON.parse(sessionStorage.getItem('mergeRouter'))
	if (!mergeRouterData) {
      //没有菜单就重新登陆
	  // if(to.query.Id && to.path == "/online-exam"){
		 //  next({
		 //    name: LOGIN_PAGE_NAME, // 跳转到登录页
		 //  		query : {
		 //  			Id : to.query.Id
		 //  		}
		 //  })
	  // }else{
		  setToken('')
		  sessionStorage.setItem('loginState', 'timeOut')
		  store.dispatch('handleTimeout')
	  // }
      // next({
      //   name: LOGIN_PAGE_NAME, // 跳转到登录页
      // })
    } else {
      if (store.state.user.hasGetInfo) {
        next()
      } else {
        store.state.user.hasGetInfo = true

        store.dispatch('setMenuData', mergeRouterData).then((res) => {
          //vuex恢复用户信息
          let userInfoData = JSON.parse(sessionStorage.getItem('userInfo'))
          if (userInfoData) {
            store.dispatch('setUserInfo', userInfoData).then((res) => { })
            // if (to.name == 'error_404') {
            //   next({
            //     name: homeName, // 跳转到homeName页
            //   })
            // } else {
            // turnTo(to, store.state.user.access, next)
            next({ ...to, replace: true })
            // }
          } else {
            sessionStorage.setItem('loginState', 'timeOut')
            store.dispatch('handleTimeout')
          }
        })
      }
    }
  }
})

router.afterEach((to) => {
  setTitle(to, router.app)
  ViewUIPlus.LoadingBar.finish();
  window.scrollTo(0, 0)
})

export default router
